import {useWeb3React} from '@web3-react/core';
import Link from 'next/link';
import {useRouter} from 'next/router';
import React from 'react';
import {ArrowLeft} from 'react-feather';
import Web3 from 'web3';
import {
	resetStores,
	resetUser,
	selectStore,
	selectUser,
	setToken,
} from '../entities/app';
import {useAppDispatch, useAppSelector} from '../hooks';
import Links, {StoreLinks} from './links';
import StoreIcon from './store-icon';

const StoreNavigation = () => {
	const router = useRouter();
	const {id} = router.query;
	const store = useAppSelector(state => selectStore(state, id as string));

	return (
		<>
			<Link href="/">
				<div className="flex h-9 w-9 cursor-pointer items-center justify-center rounded-lg bg-gray hover:opacity-50">
					<ArrowLeft size={20} color="#8A8D9E" />
				</div>
			</Link>

			<StoreIcon
				id={id as string}
				image={store?.image}
				className="ml-[30px] mr-4 h-9 w-9"
			/>

			<h3 className="mr-[30px] text-lg">{store?.name}</h3>

			<div className="mr-[15px] h-8 border border-[#393c4b]" />

			<StoreLinks id={id as string} />
		</>
	);
};

const Navbar = () => {
	const {deactivate, account} = useWeb3React<Web3>();
	const dispatch = useAppDispatch();
	const router = useRouter();
	const user = useAppSelector(selectUser);

	const disconnect = () => {
		try {
			deactivate();
			dispatch(setToken(''));
			dispatch(resetUser());
			dispatch(resetStores());
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<>
			<div className="sticky top-0 z-20 hidden w-full items-center justify-between border-b-2 border-[#2b2d39] bg-gray-darker bg-opacity-80 p-[15px] backdrop-blur-sm backdrop-filter sm:flex">
				<div className="flex items-center">
					{router.route.startsWith('/stores/[id]') ? (
						<StoreNavigation />
					) : (
						<>
							<Link href="/">
								<img
									src="/img/logo.svg"
									alt="Logo"
									className="ml-[5px] mr-5 h-[28px] w-[28px] cursor-pointer"
								/>
							</Link>

							<div className="mr-[15px] h-8 border border-[#393c4b]" />

							<Links />
						</>
					)}
				</div>

				<div
					className="flex cursor-pointer items-center rounded-lg bg-gray px-3 py-2"
					onClick={disconnect}
				>
					<p className="text-sm">
						{user.username || 'waves'}
						<span className="ml-2 text-[#62667a]">
							{account?.slice(0, 2)}...{account?.slice(-4)}
						</span>
					</p>

					<div
						className="ml-2.5 h-6 w-6 rounded-full bg-gray-dark bg-cover bg-center"
						style={{
							backgroundImage: user.profilePicture
								? `url(https://s.waves.gg/v1/user/${account?.toLowerCase()}/profile-pictures/${
										user.profilePicture
								  })`
								: '',
						}}
					/>
				</div>
			</div>

			{router.pathname !== '/stores/[id]/storefront' && (
				<div className="hidden bg-blue sm:block">
					<div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
						<div className="pr-16 sm:px-16 sm:text-center">
							<p className="font-medium text-white">
								<span>Big news! We're exiting beta soon.</span>
								<span className="block sm:ml-2 sm:inline-block">
									<a href="#" className="font-bold text-white underline">
										{' '}
										Learn more <span aria-hidden="true">&rarr;</span>
									</a>
								</span>
							</p>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Navbar;
