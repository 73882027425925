import classnames from 'classnames';
import React, {
	ButtonHTMLAttributes,
	FunctionComponent,
	useCallback,
	useState,
} from 'react';
import Loader from './loader';

interface ButtonProps {
	text: string;
	borderColor?: string;
	textColor?: string;
	bgColor?: string;
	icon?: JSX.Element;
}

const Button: FunctionComponent<
	ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({
	text,
	borderColor,
	textColor,
	bgColor,
	icon,
	className,
	onClick,
	...buttonProps
}) => {
	const [loading, setLoading] = useState(false);

	const handleClick = useCallback(
		async event => {
			if (!onClick || loading) {
				return;
			}

			setLoading(true);
			await onClick(event);
			setLoading(false);
		},
		[onClick, loading],
	);

	return (
		<button
			{...buttonProps}
			type="button"
			className={classnames(
				'inline-flex items-center justify-center rounded-lg border px-3 py-2 text-xs shadow-sm hover:opacity-50',
				borderColor || 'border-transparent',
				textColor || 'text-white',
				bgColor || 'bg-blue',
				className,
			)}
			onClick={handleClick}
		>
			{loading ? (
				<Loader className="mr-2" />
			) : icon ? (
				<div className="mr-2 flex h-5 w-5 items-center justify-center text-white">
					{icon}
				</div>
			) : null}
			{text}
		</button>
	);
};

const MemoButton = React.memo(Button);

export default MemoButton;
export {MemoButton as Button};
