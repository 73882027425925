import {useWeb3React, Web3ReactProvider} from '@web3-react/core';
import jwtDecode from 'jwt-decode';
import {DefaultSeo} from 'next-seo';
import {AppProps} from 'next/app';
import Head from 'next/head';
import React, {useEffect} from 'react';
import {Toaster} from 'react-hot-toast';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import 'rodal/lib/rodal.css';
import 'tailwindcss/tailwind.css';
import Web3 from 'web3';
import SEO from '../../next-seo.config';
import Layout from '../components/layout';
import ModalManager from '../components/modals/modal-manager';
import {
	resetStores,
	resetUser,
	selectModalIsOpen,
	selectToken,
	setToken,
} from '../entities/app';
import {useAppDispatch, useAppSelector, useEagerConnect} from '../hooks';
import {persistor, store} from '../store';
import '../styles/globals.css';
import Login from './login';

const getLibrary = (provider: any): Web3 => new Web3(provider);

const App = ({Component, pageProps}: AppProps) => {
	const token = useAppSelector(selectToken);
	const triedEagerConnect = useEagerConnect();
	const {active, account} = useWeb3React<Web3>();
	const dispatch = useAppDispatch();
	const modalIsOpen = useAppSelector(selectModalIsOpen);

	useEffect(() => {
		if (token) {
			const {exp} = jwtDecode<{exp: number}>(token);
			if (
				Date.now() > new Date(exp * 1000).getTime() ||
				(token && triedEagerConnect && !active)
			) {
				dispatch(setToken(''));
				dispatch(resetUser());
				dispatch(resetStores());
			}
		}
	}, [active]);

	// useEffect(() => {
	//   if (token && account) {
	//     const { sub } = jwtDecode<{ sub: string }>(token);
	//     if (sub.toLowerCase() !== account?.toLowerCase()) {
	//       if (token && triedEagerConnect && !active) {
	//         console.log("boomer");
	//         dispatch(setToken(""));
	//       }
	//     }
	//   }
	// }, [account]);

	if (!token) return <Login />;

	return (
		<>
			{modalIsOpen && <ModalManager />}

			<Toaster reverseOrder position="top-right" />

			<Layout>
				<Component {...pageProps} />
			</Layout>
		</>
	);
};

const Providers = (props: AppProps) => {
	return (
		<>
			<DefaultSeo {...SEO} />
			<Head>
				<link rel="icon" href="/favicon.ico" />
				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href="/apple-touch-icon.png"
				/>
				<meta
					name="viewport"
					content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
				/>
				<meta name="theme-color" content="#070707" />
				<meta name="apple-mobile-web-app-capable" content="yes" />
			</Head>

			<Web3ReactProvider getLibrary={getLibrary}>
				<Provider store={store}>
					<PersistGate persistor={persistor}>
						<App {...props} />
					</PersistGate>
				</Provider>
			</Web3ReactProvider>
		</>
	);
};

export default Providers;
