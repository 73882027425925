import classnames from 'classnames';
import React, {ChangeEvent, FunctionComponent, useRef, useState} from 'react';
import Button from './button';
import Loader from './loader';

interface ImageUploaderProps {
	value: string;
	onChange: (result: string) => void;
	bgColor?: string;
}

const ImageUploader: FunctionComponent<ImageUploaderProps> = ({
	value,
	onChange,
	bgColor,
}) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [loading, setLoading] = useState(false);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setLoading(true);

		const reader = new FileReader();

		reader.onload = async event => {
			setLoading(false);
			if (event.target?.result) {
				onChange(event.target.result.toString());
			}
		};

		if (!event.target.files?.length) {
			setLoading(false);
			return;
		}

		reader.readAsDataURL(event.target.files[0]);
	};

	const reset = () => {
		setLoading(false);
		onChange('');
	};

	return (
		<div className="flex items-center">
			<div
				className={classnames(
					'mr-3 flex h-[46px] w-[46px] items-center justify-center overflow-hidden rounded-xl bg-cover bg-center',
					bgColor || 'bg-gray',
				)}
				style={{
					backgroundImage: value ? `url(${value})` : '',
				}}
			>
				<input
					ref={inputRef}
					type="file"
					accept="image/jpg, image/jpeg, image/png"
					onChange={handleChange}
					style={{display: 'none'}}
				/>
				{loading ? (
					<Loader />
				) : !value ? (
					<img src="/img/gallery.svg" alt="Gallery" className="h-5 w-5" />
				) : null}
			</div>

			<Button
				text="Choose a file"
				bgColor="bg-gray-light"
				className="mr-3"
				onClick={() => inputRef.current?.click()}
			/>
			<Button text="Reset" bgColor="bg-gray-light" onClick={reset} />
		</div>
	);
};

export default ImageUploader;
