import {AnimatePresence, motion} from 'framer-motion';
import Hamburger from 'hamburger-react';
import {useRouter} from 'next/dist/client/router';
import React, {ReactNode, useEffect, useState} from 'react';
import Links from './links';
import Navbar from './navbar';

const Layout = (props: {children: ReactNode}) => {
	const [mobileMenuOpen, setMenuOpen] = useState(false);
	const [hasScrolled, setHasScrolled] = useState(false);
	const router = useRouter();

	useEffect(() => {
		if (typeof window === 'undefined') {
			return;
		}

		setMenuOpen(false);
	}, [router.pathname]);

	useEffect(() => {
		if (typeof window === 'undefined') {
			return;
		}

		const listener = () => {
			setHasScrolled(window.scrollY > 20);
		};

		document.addEventListener('scroll', listener);

		return () => {
			document.removeEventListener('scroll', listener);
		};
	}, []);

	const toggleMenu = () => {
		setMenuOpen(old => !old);
	};

	return (
		<div className="flex flex-col bg-gray-darker text-white sm:max-h-screen">
			<AnimatePresence>
				{mobileMenuOpen && (
					<motion.div
						initial={{opacity: 0, y: -10}}
						animate={{opacity: 1, y: 0}}
						exit={{opacity: 0}}
						className="fixed inset-0 z-10 bg-gray-darker px-8 py-24 sm:hidden"
					>
						<h1 className="mb-[15px] text-4xl font-bold">Menu</h1>

						<Links />
					</motion.div>
				)}
			</AnimatePresence>

			<div className="sticky top-0 z-20 h-32 overflow-hidden transition-all sm:hidden">
				<div
					className={`relative ${
						hasScrolled || mobileMenuOpen ? 'mt-0' : 'mx-5 mt-10'
					} bg-gray transition-all ${
						hasScrolled || mobileMenuOpen ? 'rounded-none' : 'rounded-lg'
					}`}
				>
					<div
						className={`flex justify-between space-x-2 pr-5 ${
							mobileMenuOpen ? 'bg-gray' : 'bg-transparent'
						}`}
					>
						<button
							type="button"
							className="relative z-50 block px-2 transition-all focus:ring"
							onClick={toggleMenu}
						>
							<Hamburger toggled={mobileMenuOpen} size={20} color="#8a8d9e" />
						</button>

						<div className="flex items-center overflow-hidden ">
							<img src="/img/logo.svg" alt="Logo" className="h-[28px]" />
						</div>
					</div>
				</div>
			</div>

			<Navbar />

			<main className="flex justify-center">
				<motion.div
					initial={{opacity: 0, y: -10}}
					animate={{opacity: 1, y: 0}}
					exit={{opacity: 0}}
					className="w-full overflow-auto px-5 sm:max-w-screen-lg sm:py-10"
				>
					{props.children}
				</motion.div>
			</main>
		</div>
	);
};

export default Layout;
