import React, {FunctionComponent} from 'react';
import {connect, ConnectedProps} from 'react-redux';
// @ts-expect-error Rodal does not provide any type definitions
import Rodal from 'rodal';
import {closeModal} from '../../entities/app';
import {RootState} from '../../store';
import NewStoreModal from './new-store-modal';

const modalComponentMap: any = {
	newStore: NewStoreModal,
};

const ModalManager: FunctionComponent<ReduxProps> = ({
	modalName,
	modalProps,
	dispatch,
}) => {
	const Component = modalComponentMap[modalName];

	return (
		<Rodal
			visible
			closeOnEsc
			showCloseButton={false}
			animation="zoom"
			duration={200}
			customStyles={{
				width: 'fit-content',
				height: 'fit-content',
				padding: 0,
			}}
			onClose={() => !modalProps.forceOpen && dispatch(closeModal())}
		>
			<Component modalProps={modalProps} dispatch={dispatch} />
		</Rodal>
	);
};

const mapStateToProps = ({
	app: {
		modal: {name, props},
	},
}: RootState) => ({
	modalName: name,
	modalProps: props,
});

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ModalManager);
