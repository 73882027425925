import Link from 'next/link';
import {useRouter} from 'next/router';
import {FunctionComponent} from 'react';

interface ActiveLinkProps {
	href: string;
	name: string;
}

const ActiveLink: FunctionComponent<ActiveLinkProps> = ({href, name}) => {
	const router = useRouter();
	const active = router.asPath === href;

	return (
		<Link href={href}>
			<div
				className={`rounded-lg px-3 py-2 text-sm ${
					active ? 'text-white' : 'text-[#7c7e8e]'
				} ${
					active ? 'bg-[#24252d]' : 'bg-transparent'
				} cursor-pointer transition-colors duration-200 hover:opacity-50`}
			>
				{name}
			</div>
		</Link>
	);
};

const Links = () => (
	<>
		<ActiveLink href="/" name="My Stores" />
		<ActiveLink href="/settings" name="Settings" />
	</>
);

interface StoreLinks {
	id: string;
}

export const StoreLinks: FunctionComponent<StoreLinks> = ({id}) => {
	const safeId = encodeURIComponent(id);

	return (
		<>
			<ActiveLink href={`/stores/${safeId}`} name="Overview" />
			<ActiveLink href={`/stores/${safeId}/collections`} name="Collections" />
			<ActiveLink href={`/stores/${safeId}/storefront`} name="Storefront" />
			<ActiveLink href={`/stores/${safeId}}/settings`} name="Settings" />
		</>
	);
};

export default Links;
