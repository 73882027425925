export default {
	titleTemplate: 'Waves Dash | %s',
	description:
		'Start building your own NFT collection for free, in less than 5 minutes.',
	openGraph: {
		url: 'https://dash.waves.gg',
		title: 'Waves Dash | My Stores',
		description:
			'Start building your own NFT collection for free, in less than 5 minutes.',
		images: [
			{
				url: 'https://s.waves.gg/banner.png',
			},
		],
	},
	twitter: {
		handle: '@wavesgg',
		site: '@wavesgg',
		cardType: 'summary_large_image',
	},
};
