import {store} from './store';

class Api {
	static baseUrl = 'https://api.waves.gg';

	static api: Api;

	static get instance() {
		if (!this.api) {
			this.api = new this();
		}

		return this.api;
	}

	makeRequest = async (
		pathName: string,
		method?: string,
		body?: any,
		resolveFull = false,
	) => {
		try {
			const options: RequestInit = {
				method: method || 'GET',
				headers: {'content-type': 'application/json'},
			};
			const token = store.getState().app.token;
			if (token) {
				options.headers = {
					...options.headers,
					authorization: `Bearer ${token}`,
				};
			}

			if (body) {
				options.body = JSON.stringify(body);
			}

			const res = await fetch(this.getURL(pathName), options);
			if (resolveFull) {
				return res;
			}

			return await res.json();
		} catch (_) {
			return {
				success: false,
				error: {
					code: 'fetch_failed',
					message: 'Fetch failed',
				},
			};
		}
	};

	private readonly getURL = (pathName: string) => Api.baseUrl + pathName;
}

export default Api;
