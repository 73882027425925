import classnames from 'classnames';
import React, {FunctionComponent, InputHTMLAttributes, useRef} from 'react';

export interface InputProps {
	label?: string;
	labelColor?: string;
	borderColor?: string;
	textColor?: string;
	bgColor?: string;
	placeholderColor?: string;
	icon?: JSX.Element;
}

const Input: FunctionComponent<
	InputProps & InputHTMLAttributes<HTMLInputElement>
> = ({
	label,
	labelColor,
	borderColor,
	textColor,
	bgColor,
	placeholderColor,
	icon,
	className,
	...inputProps
}) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const focusInput = () => {
		inputRef.current && inputRef.current.focus();
	};

	return (
		<div
			className={classnames(!className?.includes('w-') && 'w-52', className)}
			onClick={focusInput}
		>
			{label && (
				<label
					className={classnames(
						'mb-[6px] block text-xs',
						labelColor || 'text-[#8a8d9e]',
					)}
				>
					{label}
				</label>
			)}

			<div className="relative">
				{icon ? (
					<div className="absolute left-3 flex h-full w-[14px] cursor-text items-center text-white">
						{icon}
					</div>
				) : null}
				<input
					{...inputProps}
					ref={inputRef}
					className={classnames(
						'flex w-full rounded-lg border px-3 py-2 text-xs caret-blue shadow-sm outline-none focus:border-blue',
						icon && 'pl-[34px]',
						borderColor || 'border-[#2e3140]',
						textColor || 'text-white',
						bgColor || 'bg-gray',
						placeholderColor || 'placeholder-[#8a8d9e]',
					)}
				/>
			</div>
		</div>
	);
};

const MemoInput = React.memo(Input);

export default MemoInput;
export {MemoInput as Input};
