import {combineReducers, createStore} from 'redux';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {default as appReducer} from '../entities/app';

const persistConfig = {
	key: 'app',
	storage,
	whitelist: ['token', 'user', 'stores'],
};

export const store = createStore(
	combineReducers({
		app: persistReducer(persistConfig, appReducer),
	}),
);
export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
