import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Store} from '../interfaces';
import {RootState} from '../store';

// Define a type for the app slice state
interface AppState {
	token: string;
	user: {
		username: string;
		fullName: string;
		profilePicture: string;
		email: string;
	};
	modal: {
		isOpen: boolean;
		name: string;
		props: any;
	};
	stores: {
		[id: string]: Store;
	};
}

// Define the initial state using the AppState type
const initialState: AppState = {
	token: '',
	user: {
		username: '',
		fullName: '',
		profilePicture: '',
		email: '',
	},
	modal: {
		isOpen: false,
		name: '',
		props: {},
	},
	stores: {},
};

export const appSlice = createSlice({
	name: 'app',
	// `createSlice` will infer the state type from the `initialState` argument
	initialState,
	reducers: {
		setToken: (state, action: PayloadAction<string>) => {
			state.token = action.payload;
		},
		setUser: (state, action: PayloadAction<Partial<AppState['user']>>) => {
			state.user = {...state.user, ...action.payload};
		},
		resetUser: state => {
			state.user = initialState.user;
		},
		// Open the modal
		openModal: (state, action: PayloadAction<{name: string; props: any}>) => {
			state.modal = {
				isOpen: true,
				name: action.payload.name,
				props: action.payload.props,
			};
		},
		// Close the modal and empty the props
		closeModal: state => {
			state.modal = {isOpen: false, name: '', props: {}};
		},
		setStores: (state, action: PayloadAction<Store[]>) => {
			action.payload.forEach(store => (state.stores[store.id] = store));
		},
		createStore: (state, action: PayloadAction<Store>) => {
			state.stores[action.payload.id] = {
				...state.stores[action.payload.id],
				...action.payload,
			};
		},
		resetStores: state => {
			state.stores = initialState.stores;
		},
	},
});

export const {
	setToken,
	setUser,
	resetUser,
	openModal,
	closeModal,
	setStores,
	createStore,
	resetStores,
} = appSlice.actions;

export const selectToken = (state: RootState) => state.app.token;
export const selectModalIsOpen = (state: RootState) => state.app.modal.isOpen;
export const selectUser = (state: RootState) => state.app.user;
export const selectStores = (state: RootState) => state.app.stores;
export const selectStore = (state: RootState, id: string) =>
	state.app.stores[id];

export default appSlice.reducer;
