import {InjectedConnector} from '@web3-react/injected-connector';
import {WalletConnectConnector} from '@web3-react/walletconnect-connector';
import {WalletLinkConnector} from '@web3-react/walletlink-connector';

export const injected = new InjectedConnector({
	supportedChainIds: [1, 3, 4, 5, 42],
});

export const walletconnect = new WalletConnectConnector({
	rpc: {1: 'https://mainnet-nethermind.blockscout.com'},
	qrcode: true,
});

export const walletlink = new WalletLinkConnector({
	url: 'https://mainnet-nethermind.blockscout.com',
	appName: 'Waves',
	supportedChainIds: [1, 3, 4, 5, 42, 10, 137, 69, 420, 80001],
});
