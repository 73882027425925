import {AbstractConnector} from '@web3-react/abstract-connector';
import {useWeb3React} from '@web3-react/core';
import {NextSeo} from 'next-seo';
import {FunctionComponent, useEffect, useState} from 'react';
import {ArrowLeft} from 'react-feather';
import Web3 from 'web3';
import Api from '../api';
import Button from '../components/button';
import {injected, walletconnect, walletlink} from '../connectors';
import {setToken, setUser} from '../entities/app';
import {useAppDispatch} from '../hooks';

const api = Api.instance;

interface LoginButtonProps {
	text: string;
	onClick: () => void;
	icon: JSX.Element;
}

const LoginButton: FunctionComponent<LoginButtonProps> = props => (
	<Button
		{...props}
		borderColor="border-[#3b3f52]"
		textColor="text-[#8a8d9e]"
		bgColor="bg-transparent"
		className="rounded-[6px] py-3"
	/>
);

const Login = () => {
	const {activate, library, account} = useWeb3React<Web3>();
	const dispatch = useAppDispatch();
	const [error, setError] = useState('');
	const [nonce, setNonce] = useState('');

	const login = async () => {
		try {
			const hash = library?.utils.sha3(`${nonce}`);
			const signature = (await library?.eth.sign(
				hash as string,
				account as string,
			)) as string;

			const {data, error} = await api.makeRequest('/user/login', 'POST', {
				nonce: Number(nonce),
				signature,
			});
			if (error) {
				setError(error.message);
			} else {
				dispatch(setToken(data.token));
				dispatch(setUser(data.user));
			}
		} catch (err: any) {
			setError(err.message);
			return;
		}
	};

	useEffect(() => {
		if (nonce) login();
	}, [nonce]);

	const connect = (connector: AbstractConnector) => async () => {
		try {
			setError('');
			setNonce('');

			await activate(connector);

			const account = await connector.getAccount();
			const {data, error} = await api.makeRequest(`/user?account=${account}`);
			if (error) {
				setError(error.message);
			} else {
				setNonce(data.nonce);
			}
		} catch (err: any) {
			setError(err.message);
		}
	};

	return (
		<div className="relative flex min-h-screen flex-col items-center justify-center bg-gray-darker text-white">
			<NextSeo title="Login" />

			<div className="w-full px-5 sm:w-[340px] sm:px-0">
				<div
					className="mb-3 flex h-9 w-9 cursor-pointer items-center justify-center rounded-lg bg-gray hover:opacity-50"
					onClick={() => (window.location.href = 'https://waves.gg')}
				>
					<ArrowLeft size={20} color="#8A8D9E" />
				</div>

				<div className="overflow-hidden rounded-[10px] border border-[#2e3140] bg-gray">
					<div className="bg-gray-dark p-5">
						<img src="/img/logo.svg" alt="Logo" className="mb-5 w-10" />
						<h1 className="mb-0.5 text-2xl font-bold">Log in</h1>
						<p className="text-xs text-[#8a8d9e]">
							{error ? (
								<span className="text-red">{error}</span>
							) : (
								'Connect a wallet to continue'
							)}
						</p>
					</div>

					<div className="flex flex-col gap-1.5 p-5">
						<LoginButton
							text="Metamask"
							onClick={connect(injected)}
							icon={<img src="/img/metamask.svg" />}
						/>
						<LoginButton
							text="WalletConnect"
							onClick={connect(walletconnect)}
							icon={<img src="/img/walletconnect.svg" />}
						/>
						<LoginButton
							text="Coinbase Wallet"
							onClick={connect(walletlink)}
							icon={<img src="/img/coinbase-wallet.png" />}
						/>
						<LoginButton
							text="Opera Touch"
							onClick={() => {}}
							icon={<img src="/img/opera-touch.png" />}
						/>
					</div>
				</div>
			</div>

			<p className="mt-5 text-center text-xs text-[#8a8d9e] sm:absolute sm:bottom-20 sm:mt-0">
				Start building your own NFT collection for free, in less than 5 minutes.
			</p>
		</div>
	);
};

export default Login;
