import classnames from 'classnames';
import React, {FunctionComponent} from 'react';
import {Check} from 'react-feather';

interface StoreIconProps {
	id: string;
	image: string;
	className?: string;
}

const StoreIcon: FunctionComponent<StoreIconProps> = ({
	className,
	id,
	image,
}) => (
	<div className={classnames('relative h-10 w-10', className)}>
		<div className="absolute top-[-8.5px] left-[-8.5px] flex h-[17px] w-[17px] items-center justify-center rounded-full border-[3px] border-[#23252e] bg-blue">
			<Check size={5} strokeWidth={5} />
		</div>

		<div
			className="h-10 w-10 rounded-lg bg-gray-dark bg-cover bg-center"
			style={{
				backgroundImage: `url(https://s.waves.gg/v1/stores/${id}/images/${image})`,
			}}
		/>
	</div>
);

export default StoreIcon;
