import {useRouter} from 'next/router';
import React, {FunctionComponent, useState} from 'react';
import {ArrowLeft} from 'react-feather';
import Api from '../../api';
import {closeModal, createStore} from '../../entities/app';
import {useAppDispatch} from '../../hooks';
import Button from '../button';
import ImageUploader from '../image-uploader';
import Input from '../input';

const api = Api.instance;

interface StepProps {
	id: number;
}

const Step: FunctionComponent<StepProps> = ({id, children}) => (
	<div>
		<div className="flex items-center">
			<div className="mr-5 flex h-[42px] w-[42px] items-center justify-center rounded-full border border-[#2e3140] bg-gray text-sm text-[#8a8d9e]">
				{id}
			</div>
			<h1 className="text-lg">Tell us about it.</h1>
		</div>

		<div className="pt-[13px] pl-[62px]">{children}</div>
	</div>
);

const NewStoreModal = () => {
	const dispatch = useAppDispatch();
	const [error, setError] = useState('');
	const [slug, setSlug] = useState('');
	const [name, setName] = useState('');
	const [image, setImage] = useState('');
	const [description, setDescription] = useState('');
	const router = useRouter();

	const create = async () => {
		setError('');

		const {data, error} = await api.makeRequest('/stores', 'POST', {
			slug,
			name,
			image,
			description,
		});
		if (error) {
			setError(error.message);
		} else {
			dispatch(closeModal());
			dispatch(createStore(data.store));
			router.push(`/stores/${data.store.id}`);
		}
	};

	return (
		<div className="relative flex h-screen w-screen justify-center bg-gray-darker text-white">
			<div
				className="absolute top-[15px] left-[15px] hidden h-9 w-9 cursor-pointer items-center justify-center rounded-lg bg-gray hover:opacity-50 md:flex"
				onClick={() => dispatch(closeModal())}
			>
				<ArrowLeft size={20} color="#8A8D9E" />
			</div>

			<div className="w-full max-w-screen-sm px-5 pt-12 sm:px-0">
				<div className="mb-12 flex items-center">
					<div className="mr-4 flex h-[46px] w-[46px] items-center justify-center rounded-xl bg-gray">
						<img src="/img/gallery.svg" alt="Gallery" className="h-5 w-5" />
					</div>

					<h1 className="text-2xl font-bold">New Store</h1>
				</div>

				<div className="flex flex-col gap-5">
					<Step id={1}>
						<Input
							label="Store Slug"
							placeholder="Store Slug"
							className="mb-5"
							value={slug}
							onChange={event => setSlug(event.target.value)}
						/>
						<Input
							label="Store Name"
							placeholder="Store Name"
							className="mb-5"
							value={name}
							onChange={event => setName(event.target.value)}
						/>
						<ImageUploader value={image} onChange={setImage} />
						<Input
							label="Store Description"
							placeholder="Store Description"
							className="mt-5 w-[100%]"
							value={description}
							onChange={event => setDescription(event.target.value)}
						/>
					</Step>

					<Step id={2}>
						{/* <Dropdown label="Dropdown init" className="mb-5" /> */}
						<div className="flex items-center">
							<Button text="Create" className="mr-3" onClick={create} />
							<span className="text-xs text-red">{error}</span>
						</div>
					</Step>
				</div>
			</div>
		</div>
	);
};

export default NewStoreModal;
